'use client';

import classNames from 'classnames';
import Image, {StaticImageData} from 'next/image';
import {useState} from 'react';

export type AccordionItem = {
  header: string;
  content: string;
  button?: React.JSX.Element;
  image: StaticImageData;
};

type AccordionProps = {
  leftIcon?: React.JSX.Element;
  rightIcon?: React.JSX.Element;
  items: AccordionItem[];
  showHeaderIcon?: boolean;
  onToggleAccordion?: (index: number) => void;
  children?: React.ReactNode;
  imageContainerClassname?: string;
  imageClassname?: string;
};

export const AccordionWithImage: React.FC<AccordionProps> = ({
  onToggleAccordion,
  children,
  imageContainerClassname = 'flex justify-end items-end w-full',
  imageClassname = 'w-full lg:w-full lg:h-auto object-cover',
  ...props
}) => {
  const [active, setActive] = useState(0);
  const toggleAccordion = (index: number) => {
    if (active === index) {
      return setActive(0);
    }
    setActive(index);
    onToggleAccordion?.(index);
  };

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-5 w-full h-full">
        <div className="lg:col-span-3 flex justify-start items-center">
          <div className="max-w-3xl mx-auto py-6 px-4 md:px-10">
            <div className="mb-10">{children}</div>
            <AccordionText
              {...props}
              active={active}
              onToggleAccordion={toggleAccordion}
            />
          </div>
        </div>
        <div className={`hidden lg:flex lg:col-span-2 lg:row-span-1`}>
          <div className={imageContainerClassname}>
            <Image
              src={props.items[active].image}
              alt={props.items[active].header}
              className={`${imageClassname}`}
              placeholder="blur"
            />
          </div>
        </div>
      </div>
    </>
  );
};

type AccordionTextProps = AccordionProps & {
  active: number;
  onToggleAccordion: (index: number) => void;
};

export function AccordionText({
  items,
  active,
  leftIcon,
  rightIcon,
  showHeaderIcon,
  onToggleAccordion,
}: AccordionTextProps): React.ReactElement {
  return (
    <>
      {items.map((item, index) =>
        index === active ? (
          <div
            role="button"
            onClick={() => onToggleAccordion(1)}
            className="w-full py-2"
            key={item.header}
          >
            <div className="mb-4 flex flex-row gap-5">
              {leftIcon ? <div>{leftIcon}</div> : null}
              <div>
                <h3 className="font-medium font-inter text-[28px]">
                  {item.header}
                </h3>
              </div>
              {rightIcon ? <div>{rightIcon}</div> : null}
            </div>
            <div className="mb-4">
              <p className="text-[24px] font-inter font-normal">
                {item.content}
              </p>
            </div>
            <div className="block md:hidden  px-4 mb-4">
              <Image
                src={item.image}
                alt={item.header}
                className="w-full lg:w-auto lg:h-auto object-cover"
                placeholder="blur"
              />
            </div>
            {index !== items.length - 1 ? <hr /> : null}
          </div>
        ) : (
          <div
            role="button"
            onClick={() => onToggleAccordion(index)}
            className="w-full py-2"
            key={item.header}
          >
            <div className={classNames('py-4 flex flex-row gap-5')}>
              {leftIcon && showHeaderIcon ? <div>{leftIcon}</div> : null}
              <div>
                <h3 className="font-medium font-inter text-[28px]">
                  {item.header}
                </h3>
              </div>
              {rightIcon && showHeaderIcon ? <div>{rightIcon}</div> : null}
            </div>
            {index !== items.length - 1 ? <hr /> : null}
          </div>
        ),
      )}
    </>
  );
}
