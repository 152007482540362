type TextProps = {
  children: React.ReactNode;
  className?: string;
};
export const Text: React.FC<TextProps> = ({children, className}) => {
  return (
    <p
      className={`font-inter text-[#494949] font-normal text-[17px] ${className}`}
    >
      {children}
    </p>
  );
};
