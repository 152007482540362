'use client';

import {Popover, PopoverButton, PopoverPanel} from '@headlessui/react';
import {ChevronDownIcon} from './icons/ChevronDownIcon';
import SearchIcon from './svg/SearchIcon';
import {conditionsOptionsByLanguage} from '@website/constants/conditions';
import {useLocale, useTranslations} from 'next-intl';
import {LanguageEnum} from '@website/types/language.types';
import React, {ChangeEvent, Dispatch, SetStateAction, useState} from 'react';

type SearchableConditionsSelectProps = {
  selectedConditions: string[];
  setSelectedConditions: Dispatch<SetStateAction<string[]>>;
};

export const SearchableConditionsSelect: React.FC<
  SearchableConditionsSelectProps
> = ({selectedConditions, setSelectedConditions}) => {
  const locale = useLocale();
  const language = locale.split('-')[0] as LanguageEnum;
  const t = useTranslations('Home');

  const [conditions, setConditions] = useState(
    conditionsOptionsByLanguage[language],
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newConditions = conditionsOptionsByLanguage[language]?.filter(
      condition => condition.value.toLowerCase().includes(e.target.value),
    );

    setConditions(newConditions);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, checked} = e.target;

    if (checked) {
      // Add the condition to the selected conditions array
      setSelectedConditions(prev => [...prev, value]);
    } else {
      // Remove the condition from the selected conditions array
      setSelectedConditions(prev => prev.filter(cond => cond !== value));
    }
  };

  return (
    <Popover className="w-full">
      <>
        <PopoverButton className="w-full flex justify-between text-gray-600 items-center gap-2 px-2 py-2 border truncate border-gray-200 rounded-lg">
          <p className="truncate">
            {selectedConditions.length
              ? selectedConditions.join(', ')
              : t('yourConditions')}
          </p>
          <ChevronDownIcon />
        </PopoverButton>
        <PopoverPanel
          anchor="bottom"
          className="flex flex-col shadow-md border border-gray-100 relative rounded-lg bg-white p-4 h-[278px] w-[269px] overflow-hidden mt-1"
        >
          <div className="w-full sticky top-1 flex flex-row justify-center gap-3 rounded-md items-center bg-gray-200 px-2 py-3">
            <SearchIcon />
            <input
              type="text"
              placeholder={t('search')}
              className="bg-gray-200 focus:border-0 focus:border-none focus:outline-none"
              onChange={e => handleChange(e)}
            />
          </div>
          {conditions?.map(condition => (
            <label
              className="flex flex-row justify-start items-center gap-2 hover:bg-blue-50 p-2 rounded-lg cursor-pointer"
              key={condition.value}
            >
              <input
                type="checkbox"
                value={condition.value}
                onChange={handleCheckboxChange}
              />
              <p>{condition.label}</p>
            </label>
          ))}
        </PopoverPanel>
      </>
    </Popover>
  );
};
