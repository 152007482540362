export const QuotationOpen = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="45"
    height="39"
    viewBox="0 0 45 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 26.113V39H18.6294V20.1783H12.5C10.3177 20.1783 9.73636 20.1783 10.3177 18.1783C10.5884 17.0077 10.9534 15.8657 11.4126 14.7522C12.5315 11.9261 14.6573 8.87391 17.7902 5.59565L10.9091 0C6.88112 4.06956 4.02797 8.25217 2.34965 12.5478C0.783216 16.7304 0 21.2522 0 26.113ZM25.6294 26.113V39H44.2588V20.1783H38.1294C35.947 20.1783 35.3658 20.1783 35.947 18.1783C36.2178 17.0077 36.5828 15.8657 37.042 14.7522C38.1609 11.9261 40.2867 8.87391 43.4196 5.59565L36.5385 0C32.5105 4.06956 29.6574 8.25217 27.979 12.5478C26.4126 16.7304 25.6294 21.2522 25.6294 26.113Z"
      fill="#2E62EC"
    />
  </svg>
);
