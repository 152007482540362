const SearchIcon = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3644 8.68296C15.3644 12.374 12.3725 15.3659 8.6822 15.3659C4.99189 15.3659 2 12.374 2 8.68296C2 4.9919 4.99189 2 8.6822 2C12.3725 2 15.3644 4.9919 15.3644 8.68296ZM14.0738 15.4892C12.5928 16.6642 10.7194 17.3659 8.6822 17.3659C3.88715 17.3659 0 13.4784 0 8.68296C0 3.88749 3.88715 0 8.6822 0C13.4772 0 17.3644 3.88749 17.3644 8.68296C17.3644 10.7204 16.6627 12.5939 15.4879 14.075L20.001 18.5884L18.5867 20.0025L14.0738 15.4892Z"
        fill="#B9B9B9"
      />
    </svg>
  );
};

export default SearchIcon;
