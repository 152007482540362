'use client';

import {QuotationClose} from '@website/components/shared/svg/QuotationClose';
import {QuotationOpen} from '@website/components/shared/svg/QuotationOpen';
import classNames from 'classnames';

type ReviewsProps = {
  message: string;
  bgColor?: string;
  author: string;
};

export const Reviews: React.FC<ReviewsProps> = ({message, bgColor, author}) => {
  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center py-8 md:py-16 w-full mb-10 md:mb-24',
        {
          'bg-yellow-50': !bgColor,
          [`bg-${bgColor}`]: !!bgColor,
        },
      )}
    >
      <div className="w-full md:w-4/5 flex flex-row justify-between items-start ">
        <div className="w-1/5 flex justify-center">
          <QuotationOpen />
        </div>
        <div className="w-3/5 mb-6">
          <p className="text-[28px] text-center font-montserrat">{message}</p>
        </div>
        <div className="w-1/5 flex justify-center">
          <QuotationClose />
        </div>
      </div>
      <div>
        <p>{author}</p>
      </div>
      <div className="flex justify-center flex-row "></div>
    </div>
  );
};
