'use client';

import {StaticImport} from 'next/dist/shared/lib/get-img-props';
import Image from 'next/image';
import {Button} from '@website/components/shared/basics/Button';
import {SearchableConditionsSelect} from '../shared/SearchableConditionsSelect';
import {Text} from '@website/components/shared/basics/Text';
import {useState} from 'react';
import {useLocale} from 'next-intl';
import {CaretRightIcon} from '@website/components/shared/icons/Pointers';
import {languages} from '@website/constants';
import {getBaseUrl} from '@website/lib/services/utils';

type HeroProps = {
  title: string;
  description: string;
  image: StaticImport;
  buttonTitle: string;
  isUS?: boolean;
};

export const Hero: React.FC<HeroProps> = ({
  title,
  description,
  image,
  buttonTitle,
  isUS,
}) => {
  const [selectedConditions, setSelectedConditions] = useState<string[]>([]);
  const locale = useLocale();

  const getDirectBookingLink = () => {
    const countryCode = locale?.split('-')[1];
    const country = languages.find(
      language =>
        language.countryCode.toLowerCase() === countryCode?.toLowerCase(),
    );
    return `${getBaseUrl()}/${locale}/direct-booking/providers?profession=&state=&conditions=${encodeURIComponent(selectedConditions.join(','))}&paymentType=&country=${country?.country}`;
  };

  return (
    <div className="mb-10 p-6 md:py-16 md:px-24">
      <div className="block md:flex md:flex-row justify-between items-center">
        <div className="w-full md:w-1/2 flex justify-center">
          <section className="w-full md:w-3/5">
            <div>
              <h1 className="text-5xl font-montserrat text-center md:text-left font-bold leading-[4rem] mb-10">
                {title}
              </h1>
              <Text className="mb-10 text-center md:text-left">
                {description}
              </Text>
            </div>
            <div className="flex justify-center md:justify-start">
              {isUS ? (
                <a
                  href={`${getBaseUrl()}/${locale}/direct-booking/providers?`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    type="button"
                    borderRadius="full"
                    className="px-4 py-2 rounded-full flex flex-row items-center justify-between"
                  >
                    {buttonTitle}
                    <CaretRightIcon
                      fillColor="currentColor"
                      strokeColor="currentColor"
                      strokeWidth={0.5}
                    />
                  </Button>
                </a>
              ) : (
                <div className="w-full flex flex-col md:flex-row justify-between items-center gap-4 border border-gray-200 rounded-lg p-4">
                  <div className="w-full md:w-1/2">
                    <SearchableConditionsSelect
                      selectedConditions={selectedConditions}
                      setSelectedConditions={setSelectedConditions}
                    />
                  </div>
                  <div className="w-full md:w-1/2">
                    <a
                      href={getDirectBookingLink()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        type="button"
                        borderRadius="full"
                        className="px-4 py-2 w-full rounded-full flex flex-row items-center justify-between"
                      >
                        {buttonTitle}
                        <CaretRightIcon
                          fillColor="currentColor"
                          strokeColor="currentColor"
                          strokeWidth={0.5}
                        />
                      </Button>
                    </a>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="hidden md:flex md:w-1/2">
          <Image src={image} alt={title} className="w-5/6" placeholder="blur" />
        </div>
      </div>
    </div>
  );
};
