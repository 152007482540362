'use client';

import {
  usProviderFaqs,
  intlProviderFaqs,
  internationalFAQs,
  usFAQs,
  quartetFaqs,
} from '@website/constants';
import {CollapsiblePanel} from './basics/CollapsiblePanel';
import {useLocale, useTranslations} from 'next-intl';

import {useState} from 'react';

interface FAQprops {
  isClient?: boolean;
  isQuartet?: boolean;
}

export const FAQSection = ({isClient = true, isQuartet = false}: FAQprops) => {
  const t = useTranslations('FAQ');
  const locale = useLocale();
  const providerFaqs =
    (locale ?? 'en-us') === 'en-us' ? usProviderFaqs : intlProviderFaqs;

  let clientFaqs;
  if (isQuartet) {
    clientFaqs = quartetFaqs;
  } else {
    clientFaqs = (locale ?? 'en-us') === 'en-us' ? usFAQs : internationalFAQs;
  }

  const faqs = isClient ? clientFaqs : providerFaqs;

  const [visibleFAQs, setVisibleFAQs] = useState(4);

  const handleShowMore = () => {
    setVisibleFAQs(prevVisibleFAQs => prevVisibleFAQs + 4);
  };

  const hasMoreFAQs = visibleFAQs < faqs.length;

  return (
    <div className="w-full md:w-3/5">
      <div className="w-full px-6">
        <div className="py-10 flex justify-center">
          <h3 className="text-[40px] font-montserrat text-center md:text-left font-semibold leading-[4rem] mb-10">
            {t('faqHeading')}
          </h3>
        </div>
        <div>
          {faqs.slice(0, visibleFAQs).map((item, index) => (
            <CollapsiblePanel
              header={t(item.header)}
              content={t(item.content)}
              showLine={!(index === visibleFAQs - 1)}
              key={item.header}
            />
          ))}
        </div>
        {hasMoreFAQs ? (
          <div className="flex justify-center py-9">
            <button
              className="text-2xl hover:text-primary font-medium"
              onClick={handleShowMore}
            >
              {t('moreFAQs')}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};
