export const QuotationClose = (
  props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width="45"
    height="39"
    viewBox="0 0 45 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M45 26.113V39H26.3706V20.1783H32.5C34.6823 20.1783 35.2636 20.1783 34.6823 18.1783C34.4116 17.0077 34.0466 15.8657 33.5874 14.7522C32.4685 11.9261 30.3426 8.87391 27.2098 5.59565L34.0909 0C38.1189 4.06956 40.972 8.25217 42.6503 12.5478C44.2168 16.7304 45 21.2522 45 26.113Z"
      fill="#2E62EC"
    />
    <path
      d="M19.3706 26.113V39H0.741211V20.1783H6.87058C9.05293 20.1783 9.63422 20.1783 9.05293 18.1783C8.78219 17.0077 8.41721 15.8657 7.958 14.7522C6.83912 11.9261 4.71324 8.87391 1.58037 5.59565L8.46149 0C12.4895 4.06956 15.3426 8.25217 17.0209 12.5478C18.5874 16.7304 19.3706 21.2522 19.3706 26.113Z"
      fill="#2E62EC"
    />
  </svg>
);
