'use client';

import {ChevronDownIcon} from '@website/components/shared/icons/ChevronDownIcon';
import {useState} from 'react';

type CollapsiblePanelProps = {
  header: string;
  content: string;
  showLine?: boolean;
};

export const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({
  header,
  content,
  showLine,
}) => {
  const [active, setActive] = useState(false);
  const toggleAccordion = () => {
    setActive(!active);
  };

  return (
    <div>
      {active ? (
        <div
          role="button"
          onClick={() => toggleAccordion()}
          className="w-full"
          key={header}
        >
          <div className="mb-4 flex flex-row justify-between items-center gap-5">
            <div>
              <h3 className="font-medium font-inter text-[24px]">{header}</h3>
            </div>
            <ChevronDownIcon />
          </div>
          <div className="mb-4">
            <p className="text-[17px] font-inter font-normal">{content}</p>
          </div>
          {showLine ? (
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          ) : null}
        </div>
      ) : (
        <div
          role="button"
          onClick={() => toggleAccordion()}
          className="w-full"
          key={header}
        >
          <div className="mb-4 flex flex-row justify-between items-center gap-5">
            <div>
              <h3 className="font-medium font-inter text-[24px]">{header}</h3>
            </div>
            <ChevronDownIcon />
          </div>
          {showLine ? (
            <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
          ) : null}
        </div>
      )}
    </div>
  );
};
