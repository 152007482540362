import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/are-you-a-provider-2.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/find-a-provider-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/hero-image-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/progress-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/share-your-story.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/tailored-plan-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/wellnite-mood-flow-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/wellnite-progress-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/en/wellnite-self-awareness-en.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/find-a-provider-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/hero-image-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/progress-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/share-your-story-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/tailored-plan-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/wellnite-mood-flow-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/wellnite-progress-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/es/wellnite-self-awareness-es.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/find-a-provider-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/hero-image-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/progress-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/share-your-story-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/tailored-plan-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/wellnite-mood-flow-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/wellnite-progress-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/pt/wellnite-self-awareness-pt.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/therapist-1.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/therapist-2.jpeg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/img/therapist-3.jpeg");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/src/components/Home/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionWithImage"] */ "/vercel/path0/src/components/shared/basics/AccordionWithImage.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shared/basics/Button.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/shared/basics/RedirectToExternalLinkButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FAQSection"] */ "/vercel/path0/src/components/shared/FAQSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BenefitsHeader"] */ "/vercel/path0/src/components/shared/navigation/Header/BenefitsHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderMobileMenu"] */ "/vercel/path0/src/components/shared/navigation/Header/HeaderMobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguagePickButton"] */ "/vercel/path0/src/components/shared/navigation/Header/LanguagePickButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Reviews"] */ "/vercel/path0/src/components/shared/Reviews.tsx");
