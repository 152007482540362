import {LanguageEnum} from '@website/types/language.types';

export const conditionsOptionsUs = [
  {label: 'Addiction/Substance Abuse', value: 'Addiction/Substance Abuse'},
  {
    label: 'ADHD (Attention-Deficit/Hyperactivity Disorder)',
    value: 'ADHD (Attention-Deficit/Hyperactivity Disorder)',
  },
  {label: 'Anger Management', value: 'Anger Management'},
  {label: 'Anxiety', value: 'Anxiety'},
  {label: 'Eating Disorders', value: 'Eating Disorders'},
  {label: 'Family Issues', value: 'Family Issues'},
  {label: 'Grief (Loss)', value: 'Grief (Loss)'},
  {
    label: 'PTSD (Post-Traumatic Stress Disorder)',
    value: 'PTSD (Post-Traumatic Stress Disorder)',
  },
  {label: 'Stress Management', value: 'Stress Management'},
  {label: 'Bipolar Disorder', value: 'Bipolar Disorder'},
  {label: 'Sleep Disorders', value: 'Sleep Disorders'},
  {label: 'Friend Issues', value: 'Friend Issues'},
  {
    label: 'OCD (Obsessive-Compulsive Disorder)',
    value: 'OCD (Obsessive-Compulsive Disorder)',
  },
  {
    label: 'Professional (Career Issues)',
    value: 'Professional (Career Issues)',
  },
  {label: 'Depression', value: 'Depression'},
  {label: 'LGBTQ Identity', value: 'LGBTQ Identity'},
  {label: 'Romantic Partner Issues', value: 'Romantic Partner Issues'},
  {
    label: 'Others (please let us know in your bio)',
    value: 'Others (please let us know in your bio)',
  },
  {
    label: 'Perinatal Mental Health (Pregnancy, Postpartum, Parenting)',
    value: 'Perinatal Mental Health (Pregnancy, Postpartum, Parenting)',
  },
  {label: 'Adolescent Issues', value: 'Adolescent Issues'},
  {label: 'Adjustment Disorder', value: 'Adjustment Disorder'},
  {label: 'Domestic Violence Victims', value: 'Domestic Violence Victims'},
  {
    label: 'Domestic Violence offenders',
    value: 'Domestic Violence offenders',
  },
  {label: 'Self-esteem', value: 'Self-esteem'},
  {label: 'Multicultural', value: 'Multicultural'},
  {label: 'Personality Disorder', value: 'Personality Disorder'},
  {label: 'Identity Issues', value: 'Identity Issues'},
  {label: 'Faith Issues', value: 'Faith Issues'},
  {label: 'Seep', value: 'Seep'},
  {label: 'Adoption', value: 'Adoption'},
  {label: 'Divorce Recovery', value: 'Divorce Recovery'},
  {label: 'Homelessness', value: 'Homelessness'},
  {label: 'Group Therapy', value: 'Group Therapy'},
];

export const conditionsOptionsSpain = [
  {
    label: 'Adicción/Abuso de Sustancias',
    value: 'Adicción/Abuso de Sustancias',
  },
  {
    label: 'TDAH (Trastorno por Déficit de Atención con Hiperactividad)',
    value: 'TDAH (Trastorno por Déficit de Atención con Hiperactividad)',
  },
  {label: 'Manejo de la Ira', value: 'Manejo de la Ira'},
  {label: 'Ansiedad', value: 'Ansiedad'},
  {label: 'Trastornos Alimentarios', value: 'Trastornos Alimentarios'},
  {label: 'Problemas Familiares', value: 'Problemas Familiares'},
  {label: 'Duelo (Pérdida)', value: 'Duelo (Pérdida)'},
  {
    label: 'TEPT (Trastorno de Estrés Postraumático)',
    value: 'TEPT (Trastorno de Estrés Postraumático)',
  },
  {label: 'Manejo del Estrés', value: 'Manejo del Estrés'},
  {label: 'Trastorno Bipolar', value: 'Trastorno Bipolar'},
  {label: 'Trastornos del Sueño', value: 'Trastornos del Sueño'},
  {label: 'Problemas con Amigos', value: 'Problemas con Amigos'},
  {
    label: 'TOC (Trastorno Obsesivo-Compulsivo)',
    value: 'TOC (Trastorno Obsesivo-Compulsivo)',
  },
  {
    label: 'Problemas Profesionales (Carrera)',
    value: 'Problemas Profesionales (Carrera)',
  },
  {label: 'Depresión', value: 'Depresión'},
  {label: 'Identidad LGBTQ', value: 'Identidad LGBTQ'},
  {
    label: 'Problemas con la Pareja Romántica',
    value: 'Problemas con la Pareja Romántica',
  },
  {
    label: 'Otros (por favor infórmenos en su biografía)',
    value: 'Otros (por favor infórmenos en su biografía)',
  },
  {
    label: 'Salud Mental Perinatal (Embarazo, Postparto, Crianza)',
    value: 'Salud Mental Perinatal (Embarazo, Postparto, Crianza)',
  },
  {
    label: 'Problemas de la Adolescencia',
    value: 'Problemas de la Adolescencia',
  },
  {label: 'Trastorno de Adaptación', value: 'Trastorno de Adaptación'},
  {
    label: 'Víctimas de Violencia Doméstica',
    value: 'Víctimas de Violencia Doméstica',
  },
  {
    label: 'Agresores de Violencia Doméstica',
    value: 'Agresores de Violencia Doméstica',
  },
  {label: 'Autoestima', value: 'Autoestima'},
  {label: 'Multicultural', value: 'Multicultural'},
  {
    label: 'Trastorno de la Personalidad',
    value: 'Trastorno de la Personalidad',
  },
  {label: 'Problemas de Identidad', value: 'Problemas de Identidad'},
  {label: 'Problemas de Fe', value: 'Problemas de Fe'},
  {label: 'Sueño', value: 'Sueño'},
  {label: 'Adopción', value: 'Adopción'},
  {label: 'Recuperación del Divorcio', value: 'Recuperación del Divorcio'},
  {label: 'Desamparo', value: 'Desamparo'},
  {label: 'Terapia de Grupo', value: 'Terapia de Grupo'},
];

export const conditionsOptionsPortugal = [
  {
    label: 'Dependência/Abuso de Substâncias',
    value: 'Dependência/Abuso de Substâncias',
  },
  {
    label: 'TDAH (Transtorno de Déficit de Atenção com Hiperatividade)',
    value: 'TDAH (Transtorno de Déficit de Atenção com Hiperatividade)',
  },
  {label: 'Controle da Raiva', value: 'Controle da Raiva'},
  {label: 'Ansiedade', value: 'Ansiedade'},
  {label: 'Transtornos Alimentares', value: 'Transtornos Alimentares'},
  {label: 'Problemas Familiares', value: 'Problemas Familiares'},
  {label: 'Luto (Perda)', value: 'Luto (Perda)'},
  {
    label: 'TEPT (Transtorno de Estresse Pós-Traumático)',
    value: 'TEPT (Transtorno de Estresse Pós-Traumático)',
  },
  {label: 'Gestão do Estresse', value: 'Gestão do Estresse'},
  {label: 'Transtorno Bipolar', value: 'Transtorno Bipolar'},
  {label: 'Transtornos do Sono', value: 'Transtornos do Sono'},
  {label: 'Problemas com Amigos', value: 'Problemas com Amigos'},
  {
    label: 'TOC (Transtorno Obsessivo-Compulsivo)',
    value: 'TOC (Transtorno Obsessivo-Compulsivo)',
  },
  {
    label: 'Problemas Profissionais (Carreira)',
    value: 'Problemas Profissionais (Carreira)',
  },
  {label: 'Depressão', value: 'Depressão'},
  {label: 'Identidade LGBTQ', value: 'Identidade LGBTQ'},
  {
    label: 'Problemas com Parceiro(a) Romântico(a)',
    value: 'Problemas com Parceiro(a) Romântico(a)',
  },
  {
    label: 'Outros (por favor informe em sua biografia)',
    value: 'Outros (por favor informe em sua biografia)',
  },
  {
    label: 'Saúde Mental Perinatal (Gravidez, Pós-parto, Criação de Filhos)',
    value: 'Saúde Mental Perinatal (Gravidez, Pós-parto, Criação de Filhos)',
  },
  {label: 'Problemas na Adolescência', value: 'Problemas na Adolescência'},
  {label: 'Transtorno de Adaptação', value: 'Transtorno de Adaptação'},
  {
    label: 'Vítimas de Violência Doméstica',
    value: 'Vítimas de Violência Doméstica',
  },
  {
    label: 'Agressores de Violência Doméstica',
    value: 'Agressores de Violência Doméstica',
  },
  {label: 'Autoestima', value: 'Autoestima'},
  {label: 'Multicultural', value: 'Multicultural'},
  {
    label: 'Transtorno de Personalidade',
    value: 'Transtorno de Personalidade',
  },
  {label: 'Problemas de Identidade', value: 'Problemas de Identidade'},
  {label: 'Questões de Fé', value: 'Questões de Fé'},
  {label: 'Sono', value: 'Sono'},
  {label: 'Adoção', value: 'Adoção'},
  {label: 'Recuperação de Divórcio', value: 'Recuperação de Divórcio'},
  {label: 'Desamparo', value: 'Desamparo'},
  {label: 'Terapia de Grupo', value: 'Terapia de Grupo'},
];

export const conditionsOptionsByLanguage = {
  [LanguageEnum.en]: conditionsOptionsUs,
  [LanguageEnum.es]: conditionsOptionsSpain,
  [LanguageEnum.pt]: conditionsOptionsPortugal,
};
